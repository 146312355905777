const getPromoMessages = (advertisedPromotions) => {
  const advertisedPromos = advertisedPromotions ?? [];
  if (!advertisedPromos.length) return advertisedPromos;

  const timestamp = Date.now();
  // Function to check if a web promotion is active based on various conditions
  const isWebPromotionActive = (promoObject) => {
    // First, check if the promotion should be advertised on the web
    if (!promoObject.advertise_on_web) return false;

    // Check the start and end dates for web advertisement
    const webStartCheck = !promoObject.advertise_on_web_start || timestamp > Date.parse(promoObject.advertise_on_web_start);
    const webEndCheck = !promoObject.advertise_on_web_end || timestamp < Date.parse(promoObject.advertise_on_web_end);

    // If either `advertise_on_web_start` or `advertise_on_web_end` is provided, check if the promotion is within the valid range
    if (promoObject.advertise_on_web_start || promoObject.advertise_on_web_end) {
      return webStartCheck && webEndCheck;
    }
    // If both web-specific dates are null, fall back to checking the general `starts_at` and `expires_at` dates. If they are not present, the promotion is meant to start immediately and never expire.
    const startsAtCheck = !promoObject.starts_at || timestamp > Date.parse(promoObject.starts_at);
    const expiresAtCheck = !promoObject.expires_at || timestamp < Date.parse(promoObject.expires_at);

    return startsAtCheck && expiresAtCheck;
  };
  const activePromos = advertisedPromos.filter(isWebPromotionActive);

  return activePromos;
};

export default getPromoMessages;
